import * as React from "react"
import { graphql } from "gatsby"
import PageWrapper from "../components/PageWrapper"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "../styles/modules/GaleryPage.module.css"
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';




const GaleryPage = ({ data }) => {
  const [photoIndex, setPhotoIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);
  const [imageTitle, setImageTitle] = React.useState("");
  const images = data.allWpMediaItem.edges
  const modalImages = images.map(edge => edge.node.localFile.childImageSharp.gatsbyImageData.images.fallback.src);
  console.log(modalImages)

  const getSizes = aspectRatio => {
    if (aspectRatio > 1) {
      let maxWidth = "55vw";
      let maxHeight = "70vh";
      return { maxWidth, maxHeight }
    } else {
      let width = `calc(55vh * ${aspectRatio})`
      let maxHeight = "55vh";
      let maxWidth = "90vw";
      return { maxHeight, width, maxWidth }
    }
    //return [width,height];
  }

  React.useEffect(() => {
    setImageTitle(images[photoIndex].node.title);
  }, [photoIndex])

  const openImage = n => {
    setPhotoIndex(n);
    setIsOpen(true);
  }

  const getRatio = anImg => anImg.node.localFile.childImageSharp.fluid.aspectRatio;

  return (
    <PageWrapper>
      <section className={styles.galeryWrapper}>
        {images.map((thisImg,index) => {
          return (
            <div onClick={() => openImage(index)} key={`image-n${index}`} style={{ display: "flex", flexDirection: "column", alignItems: "flex-end", margin: "75px 0 75px 0" }} className={styles.painting}>
              <GatsbyImage style={{ ...getSizes(getRatio(thisImg)) }} imgStyle={{ objectFit: "contain", objectPosition: "right" }} transformOptions={{ fit: "contain" }} layout="fixed" image={thisImg.node.localFile.childImageSharp.gatsbyImageData} />
              <p style={{ fontSize: 14, fontWeight: "semibold", textAlign: "right", marginTop: "2ch" }}>
                {thisImg.node.title}
              </p>
              <hr style={{ width: "3ch", marginRight: 0, height: 3, background: "black", border: "none" }} />
              <div style={{ fontSize: 12, textAlign: "right" }} dangerouslySetInnerHTML={{ __html: thisImg.node.description }} />
            </div>
          )
        })}
      </section>
      {isOpen && (
        <Lightbox
          mainSrc={modalImages[photoIndex]}
          nextSrc={modalImages[(photoIndex + 1) % modalImages.length]}
          prevSrc={modalImages[(photoIndex + modalImages.length - 1) % modalImages.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() => setPhotoIndex((photoIndex + modalImages.length - 1) % modalImages.length)}
          onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % images.length)}
          wrapperClassName="imageModalWrapper"
          imagePadding={100}
        />
      )}
    </PageWrapper>
  )
}

export default GaleryPage

export const query = graphql`
query galeryQuery($slug: String!) {
  allWpMediaItem(filter: {wpParent: {node: {slug: {eq: $slug}}}}) {
    edges {
      node {
        title
        description
        localFile {
          childImageSharp {
            gatsbyImageData
            fluid {
              aspectRatio
            }
          }
        }
      }
    }
  }
  wpPage(slug: {eq: $slug}) {
    title
  }
}

`